import React, {useRef, useCallback, useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import durationFmtSetup from 'moment-duration-format';

import calendarIcon from '../../assets/img/calendar.svg';

durationFmtSetup(moment);


export default ({ eventStart }) => {

  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const eventStartMoment = useRef(moment.utc(eventStart).local());

  const now = moment.utc();
  const diff = eventStartMoment.current.diff(now);

  const duration = moment.duration(diff);


  const days = duration.get('days');
  let hours = duration.get('hours');
  let minutes = duration.get('minutes');
  let seconds = duration.get('seconds');

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  useEffect(() => {
    const int = setInterval(forceUpdate, 1000);
    return () => clearInterval(int);
  }, []);

  return (
    <div className='event-info'>
      <div className='event-info-date-wrapper'>
        <img src={calendarIcon} alt='calendar' />
        <div className='event-info-date'>
          {eventStartMoment.current.format('dddd MMM DD, [at] LT')}
        </div>
      </div>
      <div className='event-info-divider' />
      <div className='event-info-timer-wrapper'>
        <div className='event-timer-element'>
          <span className='event-timer-element-value'>{days}</span>
          <span className='event-timer-element-type'>
            <FormattedMessage id='main.eventPlaceholder.timer.days' defaultMessage='DAYS'/>
          </span>
        </div>
        <div className='event-timer-element'>
          <span className='event-timer-element-value'>{hours}</span>
          <span className='event-timer-element-type'>
            <FormattedMessage id='main.eventPlaceholder.timer.hours' defaultMessage='HOURS'/>
          </span>
        </div>
        <div className='event-timer-element'>
          <span className='event-timer-element-value'>{minutes}</span>
          <span className='event-timer-element-type'>
            <FormattedMessage id='main.eventPlaceholder.timer.minutes' defaultMessage='MINUTES'/>
          </span>
        </div>
        <div className='event-timer-element'>
          <span className='event-timer-element-value'>{seconds}</span>
          <span className='event-timer-element-type'>
            <FormattedMessage id='main.eventPlaceholder.timer.seconds' defaultMessage='SECONDS'/>
          </span>
        </div>
      </div>
    </div>
  )
};
