import Button from '../../../button_new';

const translations = {
    'en': {
        title: 'To join the event, click the button below',
        button: 'Join the meeting'
    },
    'es': {
        title: 'Para unirse al evento, haga clic en el botón de abajo',
        button: 'Unirse a la reunión'
    },
    'it': {
        title: 'Per partecipare all\'evento, clicca sul pulsante qui sotto',
        button: 'Partecipa alla riunione'
    },
    'sk': {
        title: 'Ak sa chcete pripojiť k udalosti, kliknite na tlačidlo nižšie',
        button: 'Pripojiť sa k schôdzi'
    },
    'bg': {
        title: 'За да се присъедините към събитието, щракнете върху бутона по-долу',
        button: 'Присъединете се към срещата'
    },
}

export default ({config, eventLanguage}) => {
    const translatedContent = translations[eventLanguage]

    return (
        <div className="zoom-simple__container">
            <div className='zoom-simple__wrapper'>
                <p className="zoom-simple__header">
                    {translatedContent.title}
                </p>
                <a href={config.zoomLink} target='_blank' rel='noopener noreferrer'>
                    <Button
                        className='button-new-width-100 zoom-simple__button'
                        text={translatedContent.button}
                    />
                </a>
            </div>
        </div>
    )
}
