import React from 'react';

import errorImage from '../assets/img/error.svg';

const CONTACT_URL = 'https://support.elevateskills.com/hc/en-gb';

export default () => (
  <div className='error-container'>
    <img src={errorImage} />
    <span>Event not found or has already ended. If you have any questions please <a href={CONTACT_URL}>contact us</a></span>
  </div>
);
