import React, { useEffect, memo } from 'react';
import Hls from 'hls.js';

const HLSSource = ({ src, video, onPlay, errorHandler = () => { } }) => {

  useEffect(() => {
    const hls = new Hls();
    if (Hls.isSupported()) {
      hls.loadSource(src);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, async () => {
        try {
          await onPlay();
          await video.play();
        } catch (error) {
          console.log('hls video player error => ', error)
        }
      });

      hls.once(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log('fatal network error, try to recover => ', data);
              if (errorHandler) {
                errorHandler(data);
              }
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error, try to recover => ', data);
              hls.recoverMediaError();
              break;
          }
        }
        console.log('hls network error => ', data);
      });
    }

    return () => {
      if (hls !== null) {
        hls.destroy();
      }
    };
  }, [src]);

  return (
    <source
      src={src}
      type={'application/x-mpegURL'}
    />
  );
};

export default memo(HLSSource);