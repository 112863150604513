import React, {useState} from 'react';
import copy from 'copy-to-clipboard';
import {FormattedMessage} from 'react-intl';

import config from '../../config';

import facebook from '../../assets/img/facebook.svg';
import linkedin from '../../assets/img/linkedin.svg';
import whatsapp from '../../assets/img/whatsapp.svg';
import tiktok from '../../assets/img/tiktok.svg';
import closeIcon from '../../assets/img/close.svg';

const getShareConfig = ({shareUrl}) => [
  {
    icon: facebook,
    shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=TITLE`,
  },
  {
    icon: linkedin,
    shareUrl: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
  },
  {
    icon: whatsapp,
    shareUrl: `whatsapp://send?text=${shareUrl}`,
  },
  {

  }
]


export default ({close, shareUrl}) => {
  const [showCopied, setShowCopied] = useState(false);

  const copyUrl = () => {
    if (showCopied) {
      return;
    }
    const copied = copy(shareUrl);
    if (copied) {
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    }
  }

  return (
    <>
      <div className='share-dialog-backdrop' onClick={close} />
      <div className='share-dialog-container'>
        <img src={closeIcon} className='share-dialog-close-button' onClick={close}/>
        <span className='share-dialog-title'>Share</span>
        <div className='share-dialog-share-link-wrapper'>
          <div className='share-dialog-share-link'>{shareUrl}</div>
          <button className='sign-in-button copy-link-button' onClick={copyUrl}>
            {showCopied ?
              <FormattedMessage id='common.copied' defaultMessage='COPIED' />
              :
              <FormattedMessage id='common.copy' defaultMessage='COPY'/>
            }
          </button>
        </div>
        <div className='share-dialog-socials-wrapper'>
          {
            getShareConfig({shareUrl}).map(social => (
              <a href={social.shareUrl} target='_blank'>
                <img src={social.icon} />
              </a>
            ))
          }
        </div>
      </div>
    </>
  )
}
