import React, {useState} from 'react';
import cx from 'classnames';
import mobile from 'is-mobile';
import {FormattedMessage} from 'react-intl';
import Tour from "reactour";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

import config from "../../config";
import Button from '../button_new';
import {
  PLAYER_TAB_TRANSLATIONS,
  EVENT_LANG_EN,
  EVENT_LANG_ES,
  EVENT_LANG_IT,
  EVENT_LANG_SK,
  EVENT_LANG_BG
} from '../../constants';

import logo from '../../assets/img/logo.svg';

const tabs = [
  {
    display: 'English',
    value: EVENT_LANG_EN,
  },
  {
    display: 'Spanish',
    value: EVENT_LANG_ES,
  },
  {
    display: 'Italian',
    value: EVENT_LANG_IT,
  },
  {
    display: 'Slovak',
    value: EVENT_LANG_SK,
  },
  {
    display: 'Bulgarian',
    value: EVENT_LANG_BG,
  }

];

const HeaderTabs = ({activeTab, tabs, setTab, isLoggedIn}) => (
  <div className='header-tabs'>
    <div className='header-tabs__inner'>
      {
        tabs.map(tab => {
          const className = cx({
            'header-tab': true,
            'header-tab-active': activeTab === tab.value,
          });
          if (tab.value === PLAYER_TAB_TRANSLATIONS && !isLoggedIn) {
            return null;
          }
          return (
            <div
              key={tab.value}
              className={className}
              onClick={() => setTab(tab.value)}
            >
              {tab.display}
            </div>
          )
        })
      }
    </div>
  </div>
);

const tourSteps = [
  {
    selector: '.header-tabs__inner',
    content: (
      <div>
        <div style={{fontSize:24, marginBottom:12}}>
          🌐
        </div>
        Event is availabe in three languages! You can choose your preferred lanuage here.
      </div>
    ),
    position: 'bottom'
  }
]

export default ({isFirstLogin, isLoggedIn, goToSignInPage, eventLanguage, setEventLanguage}) => {
  const [tourIsOpen,setTourIsOpen] = useState(isFirstLogin && isFirstLogin)
  const isMobile = mobile();
  return (
    <>
      <div className='header'>
        <div className='header-image-wrapper'>
          <a href={config.wowpowersURL} target='_blank'>
            <img src={logo} className='header-image' alt='logo'/>
          </a>
        </div>
        {
          !isMobile && (
            <HeaderTabs
              activeTab={eventLanguage}
              tabs={tabs}
              setTab={setEventLanguage}
              isLoggedIn={isLoggedIn}
            />
          )
        }
        <div className='header-buttons'>
          {
            !isLoggedIn && (
              <Button text={<FormattedMessage id='common.signIn' defaultMessage='SIGN IN' />} onClick={goToSignInPage} />
            )
          }
          <div className='buttons-divider' />
          {/*<LangPicker lang={lang} changeLang={changeLang} />*/}
        </div>
      </div>
      {
        isMobile && (
          <HeaderTabs
            activeTab={eventLanguage}
            tabs={tabs}
            setTab={setEventLanguage}
            isLoggedIn={isLoggedIn}
          />
        )
      }
      <Tour
        isOpen={tourIsOpen}
        steps={tourSteps}
        accentColor='#5cb7b7'
        closeWithMask={false}
        disableInteraction={true}
        className='reactour'
        lastStepNextButton='Finish'
        onAfterOpen={(element) => disableBodyScroll(element)}
        onBeforeClose={(element) => enableBodyScroll(element)}
        onRequestClose={()=>setTourIsOpen(false)}
      />
    </>
  );
}
