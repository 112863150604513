import {useCallback, useEffect, useRef} from "react";
import Player from "@vimeo/player";

export default ({ eventId }) => {
  const innerRef = useRef();
  const destroyRef = useRef(() => {})

  const initialize = useCallback(({ eventId }) => {
    const rootElement = innerRef.current;
    const player = new Player(rootElement, {
      url: `https://vimeo.com/event/${eventId}`,
      autoPlay: true,
      muted: true,
      width: 200
    });

    const AudioContext = window.AudioContext || window.webkitAudioContext
    const audioContext = !!AudioContext ? new AudioContext() : undefined;

    player.ready().then(() => {
      player.setVolume(100);
      player.play();

      if(audioContext?.state === 'running') {
        player.setMuted(false);
      }
    });

    const handleAudio = () => {
      audioContext
        ?.resume()
        .then(() => {
          player.setMuted(false);
          window.removeEventListener('click', handleAudio);
        })
        .catch(() => {});
    }

    if(audioContext?.state === 'suspended') {
      window.addEventListener('click', handleAudio, { once: true })
    }

    return {
      destroy: () => {
        window.removeEventListener('click', handleAudio);
        player.destroy();
        destroyRef.current = () => {}
      },
    }
  },[])

  useEffect(() => {
    const { destroy } = initialize({ eventId });

    destroyRef.current = destroy;

    return destroy
  }, [eventId]);

  return (
    <div className='vimeo-outer'>
      <div className='vimeo-root'>
        <div className='vimeo-inner' ref={innerRef}/>
      </div>
    </div>
  )
}