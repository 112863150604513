import React, {Fragment} from 'react';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';

import EventTimer from '../event_timer';
import Button from '../button_new';

import shareIcon from '../../assets/img/share.svg';


export default ({eventIsLive, goToSignInPage, eventStart, eventEnd, eventName, openShareDialog, openTicketsPage, isLoggedIn}) => {

  const realEventStart = moment(eventStart);
  const now = moment.utc();
  const eventStarted = now.isAfter(realEventStart);

  return (
    <div className='event-placeholder-container'>
      <span className='event-placeholder-title'>
        <FormattedMessage id='main.eventPlaceholder.title' defaultMessage='Welcome to our Virtual Event of 2024'/>
      </span>
      <div className='event-placeholder-event-name'>
        {eventName}
        {
          eventIsLive && (
            <div className='event-placeholder-live-badge'>
              <FormattedMessage id='main.eventPlaceholder.live' defaultMessage='Live'/>
            </div>
          ) || null
        }
      </div>
      {
        eventIsLive ? (
          <div className='event-placeholder-sing-in-wrapper'>
            {!isLoggedIn && (
              <Fragment>
                <div className='sign-in-button-wrapper'>
                  <Button
                    className='button-new-width-100'
                    onClick={goToSignInPage}
                    text={
                      <FormattedMessage id='common.signInToWatch' defaultMessage='SIGN IN TO WATCH'/>
                    }
                  />
                </div>
                <div className='sign-in-divider'/>
                <span>
                  <FormattedMessage id='common.dontHaveATicket' defaultMessage={`Don't have a Ticket?`}/>
                </span>
                <div className='sign-in-button-wrapper'>
                  <Button
                    className='button-new-width-100'
                    onClick={openTicketsPage}
                    text={<FormattedMessage id='common.buyTicketsNow' defaultMessage={`BUY TICKETS NOW`}/>}
                  />
                </div>
              </Fragment>
            )}
          </div>
        ) : (
          <>
            {
              eventStarted ?
                <div>
                  <FormattedMessage id='main.eventPlaceholder.eventIsAboutToStartSoon' defaultMessage={`EVENT IS ABOUT TO START SOON`}/>
                </div>
                :
                <EventTimer eventStart={eventStart} eventEnd={eventEnd}/>
            }
            <div className='event-placeholder-buttons-block'>
              {
                !isLoggedIn && (
                  <>
                    <Button
                      className='button-new-width-100'
                      onClick={goToSignInPage}
                      text={<FormattedMessage id='common.signInToWatch' defaultMessage='SIGN IN TO WATCH'/>}
                    />
                    <div className='buttons-divider' />
                  </>
                )
              }
              <Button
                text={<FormattedMessage id='common.share' defaultMessage='SHARE'/>}
                icon={shareIcon}
                onClick={openShareDialog}
              />
            </div>
          </>
        )
      }
    </div>
  )
};
