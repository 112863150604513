import React from 'react';

import LoadingSpinner from './spinner';


const style = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 5
}

const LoadingOverlay = () => {
  return (
    <div style={style}>
      <LoadingSpinner
        loading={true}
        size={200}
      />
    </div>
  );
};

export default LoadingOverlay;
