import React from 'react';
import ReactLottie from 'react-lottie';

import animationData from './pink-loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
};

const LoadingSpinner = ({size}) => {
  return (
    <ReactLottie
      style={{ width: size, height: size }}
      options={defaultOptions}
      isStopped={false}
      isPaused={false}
    />
  );
};

export default LoadingSpinner;