import React, {useState, useEffect, useCallback, useMemo} from 'react';

import * as api from './api';
import AppContext from './context';
import Main from './pages/main';
import SignIn from './pages/sign-in';
import Error from './pages/error';
import ShareDialog from './components/share_dialog';
import Loader from './components/loader';
import LangProvider from './components/lang_provider';

import {EVENT_LANG_BG, EVENT_LANG_EN, EVENT_LANG_ES, EVENT_LANG_IT, EVENT_LANG_SK, PLAYER_TAB_TRANSLATIONS, PLAYER_TAB_VIMEO, PLAYER_TAB_ZOOM} from './constants';
import config from "./config";
import {useIsOnline} from "./hooks/use_is_online";

const SESSION_CHECK_INTERVAL = 1000 * 60;
const MAYBE_GO_LIVE_INTERVAL = 1000 * 30;

const PAGE_SIGN_IN = 'PAGE_SIGN_IN';
const PAGE_MAIN = 'PAGE_MAIN';


const App = () => {
  const isOnline = useIsOnline()

  const [event, setEvent] = useState(null);
  const [eventError, setEventError] = useState(false);
  const [eventIsLive, setEventIsLive] = useState(false);
  const [viewersCount, setViewersCount] = useState(0);
  const [playerTab, setPlayerTab_] = useState(PLAYER_TAB_VIMEO);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [page, setPage] = useState(PAGE_MAIN);
  const [sessionToken, setSessionToken] = useState(null);
  const [playerConfig, setPlayerConfig] = useState(null);
  const [lang, setLang] = useState('en');
  const [eventLanguage, setEventLanguage] = useState(EVENT_LANG_EN)
  const [isFirstLogin, setIsFirstLogin] = useState(false)

  const goToMainPage = useCallback(() => setPage(PAGE_MAIN), []);
  const goToSignInPage = useCallback(() => setPage(PAGE_SIGN_IN), []);
  const openShareDialog = useCallback(() => setShareDialogOpen(true), []);
  const closeShareDialog = useCallback(() => setShareDialogOpen(false), []);
  const openBookNextEventPage = useCallback(() => {
    if (event) {
      window.open(event.nextEventUrl, '_blank');
    }
  }, [event]);
  const openTicketsPage = useCallback(() => {
    if (event) {
      window.open(event.ticketsUrl, '_blank');
    }
  }, [event]);
  const openHelpPage = useCallback(() => {
    if (event) {
      window.open(event.helpUrl, '_blank');
    }
  }, [event]);
  const isLoggedIn = useMemo(() => !!sessionToken || event?.isAuthDisabled, [sessionToken, event?.isAuthDisabled]);
  // not sure if this perfect
  const eventId = event?.eventId;

  const getEvent = useCallback(async () => {
    const event_ = await api.getEvent();
    setEvent(event_);
    if (!event_) {
      setEventError(true);
    }
  }, []);

  const setPlayerTab = useCallback((value) => {
    if (value === PLAYER_TAB_TRANSLATIONS) {
      window.open(`https://us06web.zoom.us/j/86219193161?pwd=a0x5NlN3alUzZzk0WTJlQVc2Qzhadz09`, '_blank');
    } else {
      setPlayerTab_(value);
    }
  }, [])

  const getSessionToken = useCallback(async ({ticketId, errorCb}) => {
    const sessionToken_ = await api.getSessionToken({ticketId, eventId});
    setSessionToken(sessionToken_);
    if (sessionToken_) {
      if (window?.localStorage) {
        window.localStorage.setItem('session_token', sessionToken_);
      }
      setIsFirstLogin(true);
      goToMainPage();
    } else {
      errorCb();
    }
  }, [eventId, goToMainPage])

  const verifySessionToken = useCallback(async () => {
    const isVerified = await api.verifySessionToken({sessionToken, eventId});

    if(!isVerified) {
      setSessionToken(null);
      localStorage.removeItem('session_token')
    }
  }, [sessionToken, eventId]);

  const getPlayerConfig = useCallback(async () => {
    const playerConfig_ = await api.getPlayerConfig({sessionToken, eventId});
    setPlayerConfig(playerConfig_);

    const { vimeoEventId, zoomApiKey, zoomMeetingId, zoomMeetingPassword, signature, zoomLink } = playerConfig_;
    setPlayerConfig({
      vimeoEventId,
      zoom: {
        apiKey: zoomApiKey,
        meetingId: zoomMeetingId,
        password: zoomMeetingPassword,
        signature,
        zoomLink
      }
    });
    if (!playerConfig_) {
      setSessionToken(null);
    }
  }, [sessionToken, eventId]);

  const maybeGoLive = useCallback(async () => {
    if (!eventId) {
      return;
    }
    const {isLive, viewersCount} = await api.checkLiveStatus({eventId});
    setEventIsLive(isLive);
    setViewersCount(viewersCount);
  }, [eventId]);

  const changeLang = useCallback(lang => {
    setLang(lang);
    if (window?.localStorage) {
      window.localStorage.setItem('lang', lang);
    }
  }, [])

  // populate sessionToken and lang with previously saved one;
  useEffect(() => {
    if (window?.localStorage) {
      const token = window.localStorage.getItem('session_token');
      if (token) {
        setSessionToken(token);
      }
      const lang = window.localStorage.getItem('lang');
      if (lang) {
        setLang(lang);
      }
    }
  }, []);

  // run periodic checks for session token.
  useEffect(() => {
    let interval

    if(!!sessionToken && isOnline) {
      interval = setInterval(() => verifySessionToken(), SESSION_CHECK_INTERVAL)
    }

    return () => {
      clearInterval(interval)
    }
  }, [sessionToken, verifySessionToken, isOnline]);

  // get session ticket if auth is disabled
  useEffect(() => {
    if(event?.isAuthDisabled) {
      getSessionToken({ticketId:"AUTH_DISABLED", errorCb:()=>{}})
    }
  }, [event?.isAuthDisabled]);

  // get player configuration if event is live and we have valid session token
  useEffect(() => {
    if (sessionToken && eventIsLive && !playerConfig && isOnline) {
      getPlayerConfig();
    }
  }, [sessionToken, eventIsLive, playerConfig, getPlayerConfig, isOnline]);

  // need to reset player config after event go offline
  useEffect(() => {
    if (!eventIsLive) {
      setPlayerConfig(null);
    }
  }, [eventIsLive]);

  // get event information
  useEffect(() => {
    if(isOnline) {
      getEvent()
    }
  }, [getEvent, isOnline]);

  // maybe go live
  useEffect(() => {
    let interval;

    if(isOnline) {
      maybeGoLive()

      interval = setInterval(() => maybeGoLive(), MAYBE_GO_LIVE_INTERVAL);
    }

    return () => {
      clearInterval(interval)
    }
  }, [maybeGoLive, isOnline]);

  let component;

  if (page === PAGE_MAIN) {
    component = <Main />
  }
  if (page === PAGE_SIGN_IN) {
    component = <SignIn />
  }

  if (!event) {
    if (eventError) {
      return <Error />
    }
    return (
      <Loader />
    );
  }

  return (
    <LangProvider lang={lang}>
      <AppContext.Provider
        value={{
          goToMainPage,
          goToSignInPage,
          getSessionToken,
          openShareDialog,
          openBookNextEventPage,
          openTicketsPage,
          openHelpPage,
          playerTab,
          setPlayerTab,
          isLoggedIn,
          eventIsLive,
          playerConfig,
          viewersCount,
          lang,
          changeLang,
          eventLanguage,
          setEventLanguage,
          eventName: event.name,
          eventStart: event.start,
          eventEnd: event.end,
          isFirstLogin
        }}
      >
        {
          shareDialogOpen && (
            <ShareDialog close={closeShareDialog} shareUrl={`${config.selfUrl}`}/>
          )
        }
        {component}
      </AppContext.Provider>
    </LangProvider>
  );
};


export default App;
