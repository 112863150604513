import React from 'react';

import NativePlayer from './native';
import AlternativePlayer from './alternative';
// import ZoomPlayer from './zoom';
import VimeoPlayer from "./vimeo";
import ZoomSimple from "./zoom/simple/index"

import {
  PLAYER_TAB_NATIVE,
  PLAYER_TAB_ALTERNATIVE,
  PLAYER_TAB_ZOOM,
  PLAYER_TAB_VIMEO,
} from '../../constants';


export default ({playerTab, eventLanguage, playerConfig, viewersCount}) => {
  switch (playerTab) {
    case PLAYER_TAB_NATIVE:
      return <NativePlayer src={playerConfig.liveStreamUrl} viewersCount={viewersCount} />
    case PLAYER_TAB_ALTERNATIVE:
      return <AlternativePlayer
        liveStreamAccountId={playerConfig.liveStreamAccountId}
        liveStreamEventId={playerConfig.liveStreamEventId}
      />;
    case PLAYER_TAB_ZOOM:
      // return <ZoomPlayer config={playerConfig.zoom} />;
      return <ZoomSimple config={playerConfig.zoom} eventLanguage={eventLanguage}/>
    case PLAYER_TAB_VIMEO:
      const eventId = playerConfig.vimeoEventId[eventLanguage]

      if(!!eventId) {
        return <VimeoPlayer eventId={eventId} />
      }
  }

  return null
}
