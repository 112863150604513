import React from 'react';
import moment from 'moment';

export default ({liveStreamAccountId, liveStreamEventId}) => {
  const unixTime = moment().unix();

  return (
    <div
      className='alternative-player-container'
      dangerouslySetInnerHTML={{
        __html: `<iframe 
           id="ls_embed_${unixTime}" 
           title="video" 
           src="https://livestream.com/accounts/${liveStreamAccountId}/events/${liveStreamEventId}/player?width=640&height=360&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false" 
           width="100%" 
           height="100%" 
           allowFullScreen
          > 
          <script type="text/javascript" 
            data-embed_id="ls_embed_1561564836" 
            src="https://livestream.com/assets/plugins/referrer_tracking.js">
          </script>`
        }}
    />
  );
};
