import React, {useState, useContext, useCallback} from 'react';
import cx from 'classnames';
import {FormattedMessage} from 'react-intl';

import AppContext from '../context';
import Button from '../components/button_new';

import logo from '../assets/img/logo.svg';
import backIcon from '../assets/img/back.svg';

export default () => {

  const {
    getSessionToken,
    openTicketsPage,
    openHelpPage,
    goToMainPage,
  } = useContext(AppContext);
  const [ticketId, setTicketId] = useState('');
  const [error, setError] = useState(false);

  const showError = useCallback(() => setError(true), []);
  const hideError = useCallback(() => setError(false), []);
  const handleTicketIdChange = useCallback(e => {
    hideError();
    setTicketId(e.target.value)
  }, []);
  const handleSubmit = () => {
    if (error) {
      return;
    }
    getSessionToken({ticketId, errorCb: showError});
  };

  return (
    <div className='sign-in-container'>
      <img src={logo} className='sign-in-logo' />
      <div className='sign-in-form'>
        <Button
          text={<FormattedMessage id='signIn.back' defaultMessage='BACK' />}
          icon={backIcon}
          appearance='framelessWhite'
          className='sign-in-back-button'
          onClick={goToMainPage}
        />
        <div className='sign-in-logo-wrapper'>
          <div className='sign-in-logo-border'>
            <img src={logo} />
          </div>
        </div>
        <FormattedMessage id='signIn.ticketID' defaultMessage='Ticket ID'>
        {
          (msg) => (
            <input
              value={ticketId}
              onChange={handleTicketIdChange}
              placeholder={msg}
              className={cx({'sign-in-input': true, 'input-error': error})}
            />
          )
        }
        </FormattedMessage>
        {
          error && (
            <span className='sign-in-error-message'>
              <FormattedMessage id='signIn.ticketId' defaultMessage='Invalid Ticket ID'/>
            </span>
          )
        }
        <div className='sign-in-button-wrapper'>
          <Button
            className='button-new-width-100'
            onClick={handleSubmit}
            text={<FormattedMessage id='common.signIn' defaultMessage='SIGN IN' />}
          />
        </div>
        <span className='sign-in-help-button' onClick={openHelpPage}>
          <FormattedMessage id='signIn.help' defaultMessage='Need help?' />
        </span>
        <div className='sign-in-divider'/>
        <span>
          <FormattedMessage id='common.dontHaveATicket' defaultMessage={`Don't have a Ticket?`}/>
        </span>
        <div className='sign-in-button-wrapper'>
          <Button
            className='button-new-width-100'
            onClick={openTicketsPage}
            text={<FormattedMessage id='common.buyTicketsNow' defaultMessage={`BUY TICKETS NOW`}/>}
          />
        </div>
      </div>
    </div>
  )
}
