import config from './config';

export const getEvent = async () => {
  try {
    const res = await fetch(`${config.apiURL}/events/current`);
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const verifySessionToken = async ({sessionToken, eventId}) => {
  try {
    const res = await fetch(`${config.apiURL}/verify_session`, {
      method: 'POST',
      body: JSON.stringify({sessionToken, eventId}),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return res.status === 204;
  } catch (e) {
    return false;
  }
}

export const getSessionToken = async ({ticketId, eventId}) => {
  try {
    const response = await fetch(`${config.apiURL}/verify_ticket`, {
      method: 'POST',
      body: JSON.stringify({ticketId, eventId}),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const json = await response.json();
    if (json.sessionToken) {
      return json.sessionToken;
    }
  } catch (e) {
    return null;
  }
};

export const getPlayerConfig = async ({sessionToken, eventId}) => {
  try {
    const response = await fetch(`${config.apiURL}/events/current/player_config`, {
      method: 'POST',
      body: JSON.stringify({sessionToken, eventId}),
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (e) {
    return null;
  }
};

export const checkLiveStatus = async ({eventId}) => {
  try {
    const response = await fetch(`${config.apiURL}/events/${eventId}/live_status`);
    return await response.json();
  } catch (e) {
    return {
      isLive: false,
      viewersCount: 0,
    };
  }
}
