import React, {useRef} from 'react';
import {
  Player,
  ControlBar,
  BigPlayButton,
} from 'video-react';
import HLSSource from './hls';
import 'video-react/dist/video-react.css';
import eyeIcon from '../../../assets/img/eye.svg';


export default ({src, viewersCount}) => {

  const videoRef = useRef(null);

  return (
    <Player
      aspectRatio='16:9'
      ref={videoRef}
      fluid={false}
      width={'100%'}
      height={'100%'}
      autoPlay
      muted
    >
      <div className='live-event-stats-container'>
        <p className='live-event-stats-element'><span className='live-event-dot'/>LIVE</p>
        <p className='live-event-stats-element'><img src={eyeIcon} className='live-event-eye-icon'/> {viewersCount}</p>
      </div>
      <HLSSource
        isVideoChild
        src={src}
        errorHandler={() => {console.log('ERROR')}}
        onPlay={() => console.log('PLAY')}
      />
      <BigPlayButton position="center" />
      <ControlBar />
    </Player>
  )
}
