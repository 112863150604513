import React from 'react';
import {FormattedMessage} from 'react-intl';

import Button from '../button_new';

import shareIcon from '../../assets/img/share.svg';


export default ({eventName, openShareDialog}) => (
  <div className='subheader'>
    <span className='subheader-text'>{eventName}</span>
    <Button
      text={<FormattedMessage id='common.share' defaultMessage='SHARE' />}
      icon={shareIcon}
      appearance='framelessWhite'
      onClick={openShareDialog}
    />
  </div>
);
