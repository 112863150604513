export default {
  apiURL: process.env.REACT_APP_API_URL || 'https://api.events.stg.wowpowers.com',
  selfUrl: process.env.REACT_APP_SELF_URL || 'https://events.stg.wowpowers.com/',
  wowpowersURL: process.env.REACT_APP_WOWPOWERS_URL || 'https://wowpowers.com/',
  translationsConfig: [
    {
      lang: 'Spanish 1',
      url: 'https://us02web.zoom.us/j/86724960845?pwd=TmJEbzZTMDdtVVJLU2lkYXFUb2tmQT09',
    },
    {
      lang: 'Spanish 2',
      url: 'https://us02web.zoom.us/j/86724960845?pwd=TmJEbzZTMDdtVVJLU2lkYXFUb2tmQT09',
    },
    {
      lang: 'French',
      url: 'https://us06web.zoom.us/j/5898172526',
    },
    {
      lang: 'Italian',
      url: 'https://zoom.us/j/8763625594',
    },
    {
      lang: 'Slovak',
      url: 'https://zoom.us/j/4868013014',
    }
  ]
};
