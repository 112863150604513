import React from 'react';
import cx from 'classnames';

const noop = () => {};


export default ({icon, text, appearance, className = null, onClick = noop}) => {
  const cls = cx({
    'button-new': true,
    'outline-white': appearance === 'outlineWhite',
    'frameless-white': appearance === 'framelessWhite',
    ...className && {[className]: true},
  });
  return (
    <button className={cls} onClick={onClick}>
      {
        icon && <img src={icon} className='button-new-icon'/>
      }
      {text}
    </button>
  );
}
