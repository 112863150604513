import React, {useContext} from 'react';

import AppContext from '../context';

import Header from '../components/header';
import Subheader from '../components/subheader';
import EventPlaceholder from '../components/event_placeholder';
import Player from '../components/player';

function Main() {
  const {
    isLoggedIn,
    eventIsLive,
    goToSignInPage,
    eventName,
    eventStart,
    eventEnd,
    openShareDialog,
    playerTab,
    playerConfig,
    openBookNextEventPage,
    openTicketsPage,
    viewersCount,
    lang,
    changeLang,
    eventLanguage,
    setEventLanguage,
    isFirstLogin
  } = useContext(AppContext);

  const shouldRenderPlayer = eventIsLive && isLoggedIn && !!playerConfig;

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        goToSignInPage={goToSignInPage}
        eventLanguage={eventLanguage}
        setEventLanguage={setEventLanguage}
        openBookNextEventPage={openBookNextEventPage}
        lang={lang}
        changeLang={changeLang}
        isFirstLogin={isFirstLogin}
      />
      <Subheader
        eventName={eventName}
        openShareDialog={openShareDialog}
      />
      <div className='content-container'>
        {
          shouldRenderPlayer ?
            <Player playerTab={playerTab} eventLanguage={eventLanguage} playerConfig={playerConfig} viewersCount={viewersCount}/>
            :
            <EventPlaceholder
              eventName={eventName}
              eventStart={eventStart}
              eventEnd={eventEnd}
              eventIsLive={eventIsLive}
              goToSignInPage={goToSignInPage}
              openShareDialog={openShareDialog}
              openBookNextEventPage={openBookNextEventPage}
              openTicketsPage={openTicketsPage}
              isLoggedIn={isLoggedIn}
            />
        }
      </div>
    </>
  );
}

export default Main;
