import React from 'react';
import {IntlProvider} from 'react-intl';


export default ({lang, children}) => {
  return (
    <IntlProvider lang={lang} onError={() => {}} messages={[]}>
      {children}
    </IntlProvider>
  );
};
